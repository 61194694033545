export const getCleanData = dateStr => {
	return new Date(dateStr).toISOString().replace(/T/, ' ').replace(/\..+/, '')
}

const getNumberOfPassedDays = time => {
	const dateNow = new Date()
	const dateChange = new Date(time)

	const oneDay = 1000 * 60 * 60 * 24
	const diffInTime = dateNow.getTime() - dateChange.getTime()
	const diffInDays = Math.round(diffInTime / oneDay)

	return diffInDays
}

export const getIsSevenDaysPassed = dateStr => {
	const numberOfPassedDays = getNumberOfPassedDays(dateStr)

	if (numberOfPassedDays < 7) {
		return true
	}
	return false
}
