const signUpValidation = (values, name, stateErrors) => {
	let errors = { ...stateErrors }

	switch (name) {
		case 'email':
			if (!values.email) {
				errors.email = 'Необходимо указать почту'
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
			) {
				errors.email = 'Почта введена неверно'
			} else {
				delete errors.email
			}
			break
		case 'password':
			if (!values.password) {
				errors.password = 'Необходимо указать пароль'
			} else if (/\s/g.test(values.password)) {
				errors.password = 'Пароль введен неверно'
			} else if (values.password.length < 8) {
				errors.password = 'Пароль должен содержать не менее 8 символов'
			} else {
				delete errors.password
			}

			if (
				values.repeatPassword !== '' &&
				values.repeatPassword !== values.password
			) {
				errors.repeatPassword = 'Пароли не совпадают'
			} else if (
				values.repeatPassword !== '' &&
				values.repeatPassword === values.password
			) {
				delete errors.repeatPassword
			}
			break
		case 'repeatPassword':
			if (!values.repeatPassword) {
				errors.repeatPassword = 'Необходимо ввести пароль еще раз'
			} else if (values.repeatPassword !== values.password) {
				errors.repeatPassword = 'Пароли не совпадают'
			} else {
				delete errors.repeatPassword
			}
			break
		default:
			console.log('There is no input with this name in the /signup form')
	}

	return errors
}

export default signUpValidation
