import { instance } from './instance'

let inst = instance

export const changesApi = {
	getChanges() {
		return inst.get(`news`)
	},
}

export const statusApi = {
	getStatus() {
		return inst.get(`health`)
	},
}

export const profileApi = {
	getProfile() {
		return inst.get(`users/me`)
	},
	setMailing(value) {
		return inst.patch(`users/me`, { emailNotification: value })
	},
}

export const authApi = {
	signIn(body) {
		return inst.post(`auth/login`, body)
	},
	signUp(body) {
		return inst.post(`auth/sign-up`, {
			email: body.email,
			password: body.password,
			repeatedPassword: body.repeatPassword,
		})
	},
	sendCode(code) {
		return inst.get(`auth/sign-up/confirm?code=${code}`)
	},
}
