import React, { useEffect,useContext } from 'react'
import Rate from './Rate'
import { Context } from '../../context'
import s from '../../css/rates/rates.module.css'
const rates = [
   {
      id:1,
      title:'Базовый доступ',
      description:'500 запросов в сутки',
      price:'0 руб. / месяц',
      btn:'Начать использование',
      main:false,
      active:true

   },
   {
      id:2,
      title:'Расширенный доступ',
      description:'10 000 запросов в сутки',
      price:'500 руб. / месяц',
      btn:'Связаться с поддержкой',
      main:true,
      active:true

   },
   {
      id:3,
      title:'VIP доступ',
      description:'50 000 запросов в сутки',
      price:'1500 руб. / месяц',
      btn:'Связаться с поддержкой',
      main:false,
      active:false

   }
]
const Rates = () => {
   const {isAuth} = useContext(Context)
   useEffect(()=>{
      document.title='Тарифы | Kinoposik API Unofficial'
   },[])
   return (
      <section className={s.cnt}>
         <h3 className={s.title + ` title`}>Выберите тариф, который Вам больше всего подходит</h3>
         <div className={s.rates}>
            {
               rates.map(item=>(
                  <Rate key={item.id} rate={item} isAuth={isAuth}/>
               ))
            }
            
         </div>
      </section>
   )
}

export default Rates