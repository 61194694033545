import React from 'react'
import Rates from '../components/Rates/Rates'

const RatesContainer = () => {
   
   return (
      <Rates/>
   )
}

export default RatesContainer