import React, { useEffect, useState,useContext } from 'react'
import Code from '../../../components/Code'
import codeValidation from './codeValidation'
import useForm from '../useForm'
import { useHistory } from 'react-router-dom'
import { authApi } from '../../../api/apiNew'
import { Context } from '../../../context'
const CodeContainer = () => {
	const history = useHistory()
	const { toggleShowCode } = useContext(Context)

	const [codeStatus, setCodeStatus] = useState({
		message: '',
		isLoading: false,
	})
	const [values, setValues] = useState({
		code: '',
	})
	const [errors, setErrors] = useState({
		code: '',
	})

	useEffect(() => {
		document.title = 'Код подтвержения | Kinopoisk API Unofficial'
	}, [])

	useEffect(() => {
		if (codeStatus.message === 'ok') {
			toggleShowCode(false)
			history.push({
				pathname: '/signin',
			})
		}
	}, [codeStatus.message])

	const handleSubmit = e => {
		e.preventDefault()
		setCodeStatus(prevState => ({
			...prevState,
			isLoading: true,
		}))

		authApi
			.sendCode(values.code)
			.then(res => {
				setCodeStatus(prevState => ({
					...prevState,
					message: res.status === 200 ? 'ok' : '',
					isLoading: false,
				}))
			})
			.catch(err => {
				setCodeStatus(prevState => ({
					...prevState,
					message: err.response.data.message,
				}))
			})
			.finally(() => {
				
				setCodeStatus(prevState => ({
					...prevState,
					isLoading: false,
				}))
			})
	}

	const { handleChange, disabled } = useForm(
		codeValidation,
		values,
		errors,
		setValues,
		setErrors
	)

	return (
		<Code
			handleSubmit={handleSubmit}
			handleChange={handleChange}
			values={values}
			errors={errors}
			disabled={disabled}
			codeStatus={codeStatus}
		/>
	)
}

export default CodeContainer
