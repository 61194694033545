import React from 'react'
import s from '../../css/profile/profileInfo.module.css'
import { FaRegCopy } from 'react-icons/fa'
import { IoCheckmarkDoneSharp } from 'react-icons/io5'

const ProfileInfo = ({
	email,
	apikey,
	checkboxMailing,
	isChecked,
	copyApiKey,
	isCopied,
}) => {
	return (
		<ul>
			<li>
				<h3>API-ключ:</h3>
				<div className={s.apikey}>
					<p className='common__text'>{apikey}</p>

					{isCopied ? (
						<button>
							<IoCheckmarkDoneSharp />
						</button>
					) : (
						<button onClick={copyApiKey}>
							<FaRegCopy />
						</button>
					)}
				</div>
			</li>
			<li>
				<h3>Email:</h3>
				<p className='common__text'>{email}</p>
			</li>
			<li className={s.mailing}>
				<h3>Рассылка:</h3>
				<div>
					<input
						type='checkbox'
						id={s.checkbox}
						onChange={e => checkboxMailing(e)}
						defaultChecked={isChecked}
					/>
					<label htmlFor={s.checkbox} className={s.custom__checkbox}></label>
					<p className='common__text'>Получать рассылку об обновлениях</p>
				</div>
			</li>
		</ul>
	)
}

export default ProfileInfo
