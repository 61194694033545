import React from 'react'
import s from '../../css/main/main.module.css'
import PreviewItem from './PreviewItem'
import Loader from '../Loader'
import { Link } from 'react-router-dom'
import { getCleanData, getIsSevenDaysPassed } from '../../helpers/changesHelper'

const Main = ({ previewData }) => {
	return (
		<>
			<div className={s.title}>
				<div className={s.title__body}>
					<h1>Kinopoisk API Unofficial</h1>
					<p>
						Неофициальное API для доступа к актуальной информации кинопоиска.
						Более подробную информацию вы можете найти&nbsp;
						<a
							href='https://kinopoiskapiunofficial.tech/documentation/api/'
							target='_blank'
							tabIndex='10'
							rel='noreferrer'
						>
							тут
						</a>
						.
					</p>
				</div>
			</div>
			<div className={s.preview}>
				<div className={s.preview__title}>
					<h3>Последние изменения:</h3>
					<Link to='/changes' tabIndex='11'>
						Смотреть все
					</Link>
				</div>
				<div className={s.preview__body}>
					<ul>
						{previewData ? (
							previewData.map(item => (
								<PreviewItem
									key={item.id}
									id={item.id}
									title={item.title}
									description={item.description}
									date={getCleanData(item.creationTime)}
									isSevenDaysPassed={getIsSevenDaysPassed(item.creationTime)}
								/>
							))
						) : (
							<Loader />
						)}
					</ul>
				</div>
			</div>
		</>
	)
}

export default Main
