import React, { useEffect } from 'react'
import NotFound from '../components/NotFound'

const NotFoundContainer = () => {
	useEffect(() => {
		document.title = 'Страница не найдена | Kinopoisk API Unofficial'
	}, [])

	return <NotFound />
}

export default NotFoundContainer
