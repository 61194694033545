import React from 'react'
import s from '../css/auth.module.css'
import Loader from './Loader'

const Code = ({
	handleSubmit,
	handleChange,
	values,
	errors,
	disabled,
	codeStatus,
}) => {
	return (
		<div className={s.container}>
			<div className='common__body'>
				<h3>Код подтверждения</h3>
				<form action='' onSubmit={e => handleSubmit(e)}>
					<div className={s.input}>
						<input
							type='text'
							placeholder='Введите код'
							autoComplete='off'
							onChange={e => handleChange(e)}
							defaultValue={values.login}
							name='code'
							id='code'
						/>
						{errors.code && <label htmlFor='code'>{errors.code} </label>}
					</div>

					<div className={s.btn}>
						<button id='btn' disabled={disabled || codeStatus.isLoading}>
							{codeStatus.isLoading ? (
								<Loader isButton={true} />
							) : (
								'Подтвердить регистрацию'
							)}
						</button>

						{codeStatus.message && codeStatus.message !== 'ok' && (
							<label htmlFor='btn'>{codeStatus.message}</label>
						)}
					</div>
				</form>
			</div>
		</div>
	)
}

export default Code
