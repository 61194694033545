import React, { useEffect, useState, useContext } from 'react'
import SignIn from '../../../components/SignIn'
import signInValidation from './signInValidation'
import useForm from '../useForm'
import { authApi } from '../../../api/apiNew'
import { useHistory } from 'react-router-dom'
import { Context } from '../../../context'
const SignInContainer = () => {
	
	const { setIsAuth } = useContext(Context)

	const history = useHistory()
	
	const [signInStatus, setSignInStatus] = useState({
		message: '',
		isLoading: false,
		authToken: null,
	})
	const [isPassShow, setIsPassShow] = useState(false)
	const [values, setValues] = useState({
		email: '',
		password: '',
	})
	const [errors, setErrors] = useState({
		email: '',
		password: '',
	})

	useEffect(() => {
		document.title = 'Вход в аккаунт | Kinopoisk API Unofficial'
	}, [])

	useEffect(() => {
		if (signInStatus.message === 'ok') {
			const token = signInStatus.authToken
			let search = history.location.search 
			
			history.push({
				pathname: search&&search.includes('redirect_to=/profile')?'/profile':'/',
			})

			localStorage.setItem('Authorization', token)
			setIsAuth(token)
		}
	}, [signInStatus.message])

	const setPassShow = () => {
		setIsPassShow(!isPassShow)
	}

	const handleSubmit = e => {
		e.preventDefault()
		setSignInStatus(prevState => ({
			...prevState,
			isLoading: true,
		}))

		authApi
			.signIn(values)
			.then(res => {
				//console.log(res)
				setSignInStatus(prevState => ({
					...prevState,
					message: res.status === 200 ? 'ok' : '',
					isLoading: false,
					authToken: res.headers.authorization,
				}))
			})
			.catch(err => {
				setSignInStatus(prevState => ({
					...prevState,
					message: err.response.data.message,
				}))
			})
			.finally(() => {
				setSignInStatus(prevState => ({
					...prevState,
					isLoading: false,
				}))
			})
	}

	const { handleChange, disabled } = useForm(
		signInValidation,
		values,
		errors,
		setValues,
		setErrors
	)

	return (
		<SignIn
			isPassShow={isPassShow}
			setPassShow={setPassShow}
			handleSubmit={handleSubmit}
			handleChange={handleChange}
			values={values}
			errors={errors}
			disabled={disabled}
			signInStatus={signInStatus}
		/>
	)
}

export default SignInContainer
