import React from 'react'
import s from '../css/footer.module.css'

const Footer = () => {
	return (
		<footer className={s.tag}>
			<nav className={s.menu}>
				<ul>
					<li className={s.donate}>
						<a
							href='https://yoomoney.ru/'
							target='_blank'
							rel='noreferrer'
							tabIndex='12'
						>
							Я.Деньги/ЮMoney 410011496763207
						</a>
					</li>
					<li className={s.support}>
						<a href='mailto:support@kinopoiskapiunofficial.tech' tabIndex='13'>
							support@kinopoiskapiunofficial.tech
						</a>
					</li>
					<li className={s.developers}>
						<a
							href='https://devvision.tech/'
							target='_blank'
							rel='noreferrer'
							tabIndex='14'
						>
							Produced by DevVision
						</a>
					</li>
				</ul>
			</nav>
		</footer>
	)
}

export default Footer
