import React, { useState, useEffect, useContext } from 'react'
import Header from '../components/Header'
import { Context } from '../context'

const HeaderContainer = () => {
	const [isHeaderOpen, setIsHeaderOpen] = useState(false)

	const { isAuth, setIsAuth } = useContext(Context)

	const scrollToTop = () => {
		const c = document.documentElement.scrollTop || document.body.scrollTop
		if (c > 0) {
			window.requestAnimationFrame(scrollToTop)
			window.scrollTo(0, c - c / 8)
		}
	}

	useEffect(() => {
		const body = document.body

		if (isHeaderOpen) {
			body.classList.add('header__open')
		} else {
			body.classList.remove('header__open')
		}
	}, [isHeaderOpen])

	const toggleHeader = () => {
		scrollToTop()
		setIsHeaderOpen(!isHeaderOpen)
	}

	const signOutFunc = e => {
		e.preventDefault()
		setIsAuth(null)
	}

	return (
		<Header
			toggleHeader={toggleHeader}
			setIsHeaderOpen={setIsHeaderOpen}
			isHeaderOpen={isHeaderOpen}
			isAuth={isAuth}
			signOutFunc={signOutFunc}
		/>
	)
}

export default HeaderContainer
