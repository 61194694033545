import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { Context } from './context'
import { getIsAuthTokenHistory } from './api/instance'

// Header
import HeaderContainer from './containers/HeaderContainer'
// Main
import MainContainer from './containers/MainContainer'
// Changes
import ChangesContainer from './containers/ChangesContainer'
// Status
import StatusContainer from './containers/StatusContainer'
// Profile
import ProfileContainer from './containers/ProfileContainer'
// Footer
import FooterContainer from './containers/FooterContainer'
// SignIn
import SignInContainer from './containers/Auth/SignIn/SignInContainer'
// SignUp
import SignUpContainer from './containers/Auth/SignUp/SignUpContainer'
// Code
import CodeContainer from './containers/Auth/Code/CodeContainer'
// Not Found
import NotFoundContainer from './containers/NotFoundContainer'
//Rates 
import RatesContainer from './containers/RatesContainer'

const App = () => {
	const [isShowCode, setIsShowCode] = useState(false)
	const [isAuth, setIsAuth] = useState(null)
	const [isMounted, setIsMounted] = useState(false)
	const history = useHistory()
	const toggleShowCode = (boolval) => {
		setIsShowCode(boolval)
	}
	useEffect(() => {
		if (!isMounted) {
			setIsMounted(true)
		} else {
			if (isAuth === null) {
				localStorage.removeItem('Authorization')
			}
			getIsAuthTokenHistory(isAuth, setIsAuth, history)
		}
	}, [isAuth])

	useEffect(() => {
		const authToken = localStorage.getItem('Authorization')
		setIsAuth(authToken)
	}, [])

	return (
		<Context.Provider
			value={{ toggleShowCode, isAuth, setIsAuth, isMounted, setIsMounted }}
		>
			<div className='container'>
				<div className='container__body'>
					<HeaderContainer />
					<main className='main'>
						<Switch>
							
							<Route exact path='/code'>
								{/* <CodeContainer /> */}
								{isShowCode ? <CodeContainer /> : <Redirect to='/' />}
							</Route>
							<Route exact path='/signup'>
								{isAuth ? <Redirect to='/' /> : <SignUpContainer />}
							</Route>
							<Route exact path='/signin'>
								{isAuth ? <Redirect to='/' /> : <SignInContainer />}
							</Route>
							<Route exact path='/profile'>
								<ProfileContainer />
							</Route>
							<Route exact path='/status'>
								<StatusContainer />
							</Route>
							<Route exact path='/changes'>
								<ChangesContainer />
							</Route>
							<Route exact path='/'>
								<MainContainer />
							</Route>
							<Route exact path='/rates'>
								<RatesContainer/>
							</Route>
							<Route>
								<NotFoundContainer />
							</Route>
						</Switch>
					</main>
					<FooterContainer />
				</div>
			</div>
		</Context.Provider>
	)
}

export default App
