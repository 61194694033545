import React, { useEffect, useState } from 'react'
import { statusApi } from '../api/apiNew'
import Status from '../components/Status/Status'

const StatusContainer = () => {
	const [statusData, setStatusData] = useState(null)

	useEffect(() => {
		statusApi
			.getStatus()
			.then(res => setStatusData(res.data))
			.catch(err => console.log(err))

		document.title = 'Статус | Kinopoisk API Unofficial'
	}, [])

	return <Status statusData={statusData} />
}

export default StatusContainer
