import React, { useEffect, useState } from 'react'
import Changes from '../components/Changes/Changes'
import { changesApi } from '../api/apiNew'

const ChangesContainer = () => {
	const [changesData, setChangesData] = useState(null)
	const { hash } = window.location

	const scrollTo = (element, to, duration) => {
		if (duration <= 0) return
		let difference = to - element.scrollTop
		let perTick = (difference / duration) * 10

		setTimeout(() => {
			element.scrollTop = element.scrollTop + perTick
			if (element.scrollTop === to) return
			scrollTo(element, to, duration - 10)
		}, 10)
	}

	useEffect(() => {
		changesApi
			.getChanges()
			.then(res => setChangesData(res.data))
			.catch(err => console.log(err))
			.finally(() => {
				if (hash !== '') {
					const id = hash.replace('#', '')
					const element = document.getElementById(id)

					scrollTo(document.documentElement, element.offsetTop, 300)
				}
			})

		document.title = 'Изменения | Kinopoisk API Unofficial'
	}, [])

	return <Changes changesData={changesData} />
}

export default ChangesContainer
