import React, { memo } from 'react'
import s from '../../css/changes/changesItem.module.css'

const ChangesItem = memo(({ id, title, description, date }) => {
	return (
		<li className={s.tag}>
			<article className='common__body' id={id}>
				<div>
					<h3>{title}</h3>
					<time>{date}</time>
				</div>
				<p className='common__text'>{description}</p>
			</article>
		</li>
	)
})

export default ChangesItem
