import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import s from '../../css/main/previewItem.module.css'

const PreviewItem = memo(
	({ id, title, description, date, isSevenDaysPassed }) => {
		return (
			<li className={s.tag}>
				<article>
					<Link to={`/changes#${id}`}>
						<div>
							<h3>{title}</h3>
							<time>
								{date}{' '}
								{isSevenDaysPassed ? <span className={s.new}>New!</span> : ''}
							</time>
						</div>
						<p className='common__text'>{description}</p>
					</Link>
				</article>
			</li>
		)
	}
)

export default PreviewItem
