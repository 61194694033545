import React from 'react'
import StatusItem from './StatusItem'
import s from '../../css/status/status.module.css'
import Loader from '../Loader'

const Status = ({ statusData }) => {
	return statusData ? (
		<div className={s.container}>
			<div className='common__body'>
				<div className={s.title}>
					<h3>Маршрут:</h3>
					<h3>Статус:</h3>
				</div>
				<ul>
					{statusData.endpointToStatusList.map((item, i) => (
						<StatusItem key={i} endpoint={item.first} status={item.second} />
					))}
				</ul>
			</div>
		</div>
	) : (
		<Loader />
	)
}

export default Status
