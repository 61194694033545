import axios from 'axios'

let isAuthState
let setIsAuthState
let routerHistory

export const getIsAuthTokenHistory = (isAuth, setIsAuth, history) => {
	isAuthState = isAuth
	setIsAuthState = setIsAuth
	routerHistory = history
}

const baseURL = 'https://kinopoiskapiunofficial.tech/api/v1/'
const headers = {
	'Content-Type': 'application/json',
}

export const instance = axios.create({
	baseURL,
	headers,
})

instance.interceptors.request.use(
	config => {
		const { url } = config

		if (url === 'users/me') {
			config.headers.Authorization = isAuthState
		}

		return config
	},
	err => {
		return Promise.reject(err)
	}
)

instance.interceptors.response.use(
	res => {
		return res
	},
	err => {
		if (!err.response) {
			return Promise.reject(err)
		}

		const {
			status,
			config: { url },
		} = err.response

		if (url === 'users/me') {
			if (status === 403) {
				setIsAuthState(null)

				if (routerHistory) {
					routerHistory.push('/')
				} else {
					window.location = '/'
				}
			}
		}
		return Promise.reject(err)
	}
)
