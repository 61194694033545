const codeValidation = (values, name, stateErrors) => {
	let errors = { ...stateErrors }

	switch (name) {
		case 'code':
			if (!values.code) {
				errors.code = 'Необходимо ввести код'
			} else if (/\s|\D/g.test(values.code)) {
				errors.code = 'Код введен неверно'
			} else if (values.code.length < 4) {
				errors.code = 'Код введен неверно'
			} else {
				delete errors.code
			}
			break
		default:
			console.log('There is no input with this name in the /code form')
	}

	return errors
}

export default codeValidation
