import React, { useEffect, useState, useContext } from 'react'
import Profile from '../components/Profile/Profile'
import { profileApi } from '../api/apiNew'
import { useHistory } from 'react-router-dom'
import { Context } from '../context'

const ProfileContainer = () => {
	const [profileData, setProfileData] = useState(null)
	const [isCopied, setIsCopied] = useState(false)
	const { isAuth, isMounted, setIsMounted } = useContext(Context)
	const history = useHistory()

	useEffect(() => {
		if (isMounted) {
			if (isAuth === null) {
				history.push('/')
			} else {
				profileApi
					.getProfile()
					.then(res => setProfileData(res.data))
					.catch(err => console.log(err))
			}
		} else {
			setIsMounted(true)
		}

		document.title = 'Профиль | Kinopoisk API Unofficial'
	}, [isAuth, isMounted])

	const copyApiKey = () => {
		navigator.clipboard.writeText(profileData.token)
		setIsCopied(true)
		setTimeout(() => {
			setIsCopied(false)
		}, 3000)
	}

	const checkboxMailing = e => {
		profileApi.setMailing(e.target.checked).catch(err => console.log(err))
	}

	return (
		<Profile
			isCopied={isCopied}
			copyApiKey={copyApiKey}
			profileData={profileData}
			checkboxMailing={checkboxMailing}
		/>
	)
}

export default ProfileContainer
