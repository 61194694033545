import React, { memo } from 'react'
import s from '../../css/status/statusItem.module.css'

const StatusContainer = memo(({ endpoint, status }) => {
	return (
		<li className={s.tag}>
			<div className='common__text'>{endpoint}</div>
			<div className='common__text'>
				{status === 200 ? (
					<div className={s.ok}>ОК</div>
				) : (
					<div className={s.err}>ОШИБКА</div>
				)}
			</div>
		</li>
	)
})

export default StatusContainer
