import React from 'react'
import Loader from '../Loader'
import s from '../../css/profile/profile.module.css'
import ProfileInfo from './ProfileInfo'

const Profile = ({ profileData, checkboxMailing, copyApiKey, isCopied }) => {
	return profileData ? (
		<div className={s.row}>
			<div className={s.forty}>
				<ProfileInfo
					email={profileData.email}
					apikey={profileData.token}
					checkboxMailing={checkboxMailing}
					isChecked={profileData.emailNotification}
					copyApiKey={copyApiKey}
					isCopied={isCopied}
				/>
			</div>
			<div className={s.sixty}>
				<div className='common__body'>
					<h3>Пример использования:</h3>
					<code
						dangerouslySetInnerHTML={{
							__html: `	fetch('https://kinopoiskapiunofficial.tech/api/v2.2/films/301',
							{ <br>
							&nbsp;&nbsp;&nbsp;&nbsp;method: 'GET', <br>
							&nbsp;&nbsp;&nbsp;&nbsp;headers: { <br>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'X-API-KEY':
							'${profileData.token}', <br>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'Content-Type':
							'application/json',
							<br>
							&nbsp;&nbsp;&nbsp;&nbsp;}, <br>
							}) <br>
							&nbsp;&nbsp;&nbsp;&nbsp;.then(res =&gt; res.json())
							<br>&nbsp;&nbsp;&nbsp;&nbsp;.then(json =&gt; console.log(json))
							<br>&nbsp;&nbsp;&nbsp;&nbsp;.catch(err =&gt; console.log(err))`,
						}}
					></code>
				</div>
			</div>
		</div>
	) : (
		<Loader />
	)
}

export default Profile
