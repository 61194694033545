const signInValidation = (values, name, stateErrors) => {
	let errors = { ...stateErrors }

	switch (name) {
		case 'email':
			if (!values.email) {
				errors.email = 'Необходимо указать почту'
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
			) {
				errors.email = 'Почта введена неверно'
			} else {
				delete errors.email
			}
			break
		case 'password':
			if (!values.password) {
				errors.password = 'Необходимо указать пароль'
			} else if (/\s/g.test(values.password)) {
				errors.password = 'Пароль введен неверно'
			} else if (values.password.length < 6) {
				errors.password = 'Пароль введен неверно'
			} else {
				delete errors.password
			}
			break
		default:
			console.log('There is no input with this name in the /signin form')
	}

	return errors
}

export default signInValidation
