import React from 'react'
import s from '../css/header.module.css'
import { Link } from 'react-router-dom'

const Header = ({setIsHeaderOpen, toggleHeader, isHeaderOpen, isAuth, signOutFunc }) => {
	return (
		<header className={`${s.tag} ${isHeaderOpen ? s.open : ''}`}>
			<div className={s.body}>
				<div className={s.burger} onClick={toggleHeader}>
					<span></span>
					<span></span>
					<span></span>
				</div>
				<div className={s.logo}>
					<Link to='/' tabIndex='1'>
						Kinopoisk API Unofficial
					</Link>
				</div>
				<div className={s.menucnt}>
					<div className={s.menucnt__container}>
						<nav className={s.menu}>
							<ul>
								<li>
									<Link onClick={()=>setIsHeaderOpen(false)} className={s.rates} to='/rates' tabIndex='2'>
										Тарифы
									</Link>
								</li>
								<li>
									<a onClick={()=>setIsHeaderOpen(false)}
										tabIndex='3'
										href='https://kinopoiskapiunofficial.tech/documentation/api/'
										target='_blank'
										rel='noreferrer'
									>
										Документация
									</a>
								</li>
								<li>
									<Link onClick={()=>setIsHeaderOpen(false)} to='/changes' tabIndex='4'>
										Изменения
									</Link>
								</li>
								<li>
									<Link onClick={()=>setIsHeaderOpen(false)} to='/status' tabIndex='5'>
										Статус
									</Link>
								</li>
								{isAuth ? (
									<li>
										<Link onClick={()=>setIsHeaderOpen(false)} to='/profile' tabIndex='6'>
											Профиль
										</Link>
									</li>
								) : (
									''
								)}
							</ul>
						</nav>

						<div className={s.auth}>
							<ul>
								{isAuth ? (
									''
								) : (
									<>
										<li className={s.signin}>
											<Link onClick={()=>setIsHeaderOpen(false)} to='/signin' tabIndex='7'>
												Войти
											</Link>
										</li>
										<li className={s.signup}>
											<Link onClick={()=>setIsHeaderOpen(false)} to='/signup' tabIndex='8'>
												Зарегистрироваться
											</Link>
										</li>
									</>
								)}

								{isAuth ? (
									<li className={s.signout}>
										<a  href='!#' tabIndex='9' onClick={e => {signOutFunc(e);setIsHeaderOpen(false)}}>
											Выйти
										</a>
									</li>
								) : (
									''
								)}
							</ul>
						</div>
					</div>
					
				</div>
				
			</div>
			<div className={s.overlay} onClick={toggleHeader}></div>
		</header>
	)
}

export default Header
