import React from 'react'
import s from '../css/loader.module.css'

const Loader = ({ isButton }) => {
	return (
		<div className={`${s.loader} ${isButton ? s.active : ''}`}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	)
}

export default Loader
