import React from 'react'
import s from '../../css/changes/changes.module.css'
import ChangesItem from './ChangesItem'
import Loader from '../Loader'
import { getCleanData } from '../../helpers/changesHelper'

const Changes = ({ changesData }) => {
	return (
		<div className={s.container}>
			<ul>
				{changesData ? (
					changesData.map(item => (
						<ChangesItem
							key={item.id}
							id={item.id}
							title={item.title}
							description={item.description}
							date={getCleanData(item.creationTime)}
						/>
					))
				) : (
					<Loader />
				)}
			</ul>
		</div>
	)
}

export default Changes
