import React from 'react'
import s from '../../css/rates/rates.module.css'
import icontime from '../../img/rateicontime.svg'
import iconprice from '../../img/rateiconprice.svg'
import { Link } from 'react-router-dom'

const Rate = ({rate,isAuth}) => {
   
   const {id,title,description,price,btn,main,active} = rate

   return (
      <div data-rateid={id} className={s.rate + ` ${main&&s.mainRate} ${!active&&s.blur} common__body`}>
         <h3 className={s.rate__title }>
            {title}
         </h3>
         <div className={s.rate__description}>
            <p className={s.rate__p}>
               <img src={icontime} alt="@" />
               {description}
            </p>
            <p className={s.rate__p}>
               <img src={iconprice} alt="$" />
               {price}
            </p>
         </div>
         <button className={s.rate__btn}>
            {
               active
               
               ?

               id===1
               ?

               <Link className={s.link} to={isAuth?'/profile':'/signin?redirect_to=/profile'} >{btn}</Link>

               :
               <a className={s.link} href="mailto:support@kinopoiskapiunofficial.tech">{btn}</a>
            
               :
               <>{btn}</> 
            }
            
         </button>
      </div>
   )
}

export default Rate