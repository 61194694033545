import React, { useEffect, useState } from 'react'
import Main from '../components/Main/Main'
import { changesApi } from '../api/apiNew'

const MainContainer = () => {
	const [previewData, setPreviewData] = useState(null)

	// useEffect(() => {
	// 	console.log(previewData)
	// }, [previewData])

	useEffect(() => {
		changesApi
			.getChanges()
			.then(res => setPreviewData(res.data))
			.catch(err => console.log(err))

		document.title = 'Главная | Kinopoisk API Unofficial'
	}, [])

	return <Main previewData={previewData} />
}

export default MainContainer
