import React from 'react'
import s from '../css/auth.module.css'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import Loader from './Loader'

const SignUp = ({
	isPassShow,
	setPassShow,
	handleSubmit,
	handleChange,
	values,
	errors,
	disabled,
	signUpStatus,
}) => {
	return (
		<div className={s.container}>
			<div className='common__body'>
				<h3>Регистрация аккаунта</h3>
				<form action='' onSubmit={e => handleSubmit(e)}>
					<div className={s.input}>
						<input
							type='email'
							placeholder='Электронная почта'
							autoComplete='off'
							onChange={e => handleChange(e)}
							defaultValue={values.login}
							name='email'
							id='email'
						/>
						{errors.email && <label htmlFor='email'>{errors.email} </label>}
					</div>

					<div className={s.input}>
						<input
							type={isPassShow ? 'text' : 'password'}
							placeholder='Пароль'
							autoComplete='new-password'
							onChange={e => handleChange(e)}
							defaultValue={values.password}
							name='password'
							id='password'
						/>

						<div className={s.eye} onClick={setPassShow}>
							{isPassShow ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
						</div>

						{errors.password && (
							<label htmlFor='password'>{errors.password} </label>
						)}
					</div>

					<div className={s.input}>
						<input
							type={isPassShow ? 'text' : 'password'}
							placeholder='Повторите пароль'
							autoComplete='new-password'
							onChange={e => handleChange(e)}
							defaultValue={values.repeatPassword}
							name='repeatPassword'
							id='repeatPassword'
						/>

						<div className={s.eye} onClick={setPassShow}>
							{isPassShow ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
						</div>

						{errors.repeatPassword && (
							<label htmlFor='repeatPassword'>{errors.repeatPassword} </label>
						)}
					</div>

					<div className={s.btn}>
						<button id='btn' disabled={disabled || signUpStatus.isLoading}>
							{signUpStatus.isLoading ? (
								<Loader isButton={true} />
							) : (
								'Зарегистрироваться'
							)}
						</button>

						{signUpStatus.message && signUpStatus.message !== 'ok' && (
							<label htmlFor='btn'>{signUpStatus.message}</label>
						)}
					</div>
				</form>
			</div>
		</div>
	)
}

export default SignUp
