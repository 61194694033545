import { useState, useEffect } from 'react'

const useForm = (validation, values, errors, setValues, setErrors) => {
	const [disabled, setDisabled] = useState(true)

	const handleChange = e => {
		const { name, value } = e.target

		const newObjValues = {
			...values,
			[name]: value,
		}

		setValues(newObjValues)
		setErrors(validation(newObjValues, name, errors))
	}

	useEffect(() => {
		if (Object.keys(errors).length === 0) {
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	}, [errors, setDisabled])

	return { handleChange, values, errors, disabled }
}

export default useForm
