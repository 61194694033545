import React, { useState, useEffect, useContext } from 'react'
import signUpValidation from './signUpValidation'
import useForm from '../useForm'
import { useHistory } from 'react-router-dom'
import SignUp from '../../../components/SignUp'
import { Context } from '../../../context'
import { authApi } from '../../../api/apiNew'

const SignUpContainer = props => {
	const [isPassShow, setIsPassShow] = useState(false)
	const history = useHistory()
	const { toggleShowCode } = useContext(Context)

	const [signUpStatus, setSignUpStatus] = useState({
		message: '',
		isLoading: false,
	})

	useEffect(() => {
		if (signUpStatus.message === 'ok') {
			

			toggleShowCode(true)
			history.push({
				pathname: '/code',
			})
		}
	}, [signUpStatus.message])



	useEffect(() => {
		document.title = 'Регистрация аккаунта | Kinopoisk API Unofficial'
	}, [])

	const [values, setValues] = useState({
		email: '',
		password: '',
		repeatPassword: '',
	})

	const [errors, setErrors] = useState({
		email: '',
		password: '',
		repeatPassword: '',
	})

	const handleSubmit = e => {
		e.preventDefault()

		setSignUpStatus(prevState => ({
			...prevState,
			isLoading: true,
		}))

		authApi
			.signUp(values)
			.then(res => {
				setSignUpStatus(prevState => ({
					...prevState,
					message: res.status === 200 ? 'ok' : '',
					isLoading: false,
				}))
			})
			.catch(err => {
				setSignUpStatus(prevState => ({
					...prevState,
					message: err.response.data.message,
				}))
			})
			.finally(() => {
				setSignUpStatus(prevState => ({
					...prevState,
					isLoading: false,
				}))
			})
	}

	const { handleChange, disabled } = useForm(
		signUpValidation,
		values,
		errors,
		setValues,
		setErrors
	)

	const setPassShow = () => {
		setIsPassShow(!isPassShow)
	}

	return (
		<SignUp
			isPassShow={isPassShow}
			setPassShow={setPassShow}
			handleSubmit={handleSubmit}
			handleChange={handleChange}
			values={values}
			errors={errors}
			disabled={disabled}
			signUpStatus={signUpStatus}
		/>
	)
}

export default SignUpContainer
